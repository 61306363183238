import "./about.scss"
import {LocationOn} from "@material-ui/icons";

export default function About() {

    
    return (
        <div className="about" id="about">
            <h2>About</h2>
            <div className="bottom">
                <p>
                    Born and raised in Venezuela, I came to the U.S when I was 17 years old to pursue my A.A 
                    degree in International Business at Santa Fe College and B.S in Food and Resource Economics at University of Florida. 
                    Due to unforseen situations in my home country, I had to switch my career's direction.
                    As a result, I discover a big passion in programming. I graduated from my M.S degree in Information Technology 
                    at University of Florida in December 2021.<br></br> 
                    I have experience in Python, JavaScript, Java, C#, HTML, CSS, ReactJS, SQL, and MongoDB.
                </p>
                <span>
                    Currently in:<br></br>
                    <a href = "https://www.google.com/maps/place/Miami,+FL/@25.7823907,-80.2994989,12z/data=!3m1!4b1!4m5!3m4!1s0x88d9b0a20ec8c111:0xff96f271ddad4f65!8m2!3d25.7616798!4d-80.1917902" 
                    id = "location" target = "_blank"><LocationOn></LocationOn> Miami, FL.</a>
                </span>
            </div>
        </div>
    )
}
